/*Abstracts*/
@use './scss/abstracts/_mixins';
@use './scss/abstracts/_variables';

/*Base*/
@use './scss/base/base';
@use './scss/base/resets';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');