.accreditation{
    @apply flex justify-center align-middle;
    width: 43.75rem !important;

    .scribd{
        height: 56.25rem !important;
    }

    .toolbar_top{
        @apply hidden;
    }
}
