.homeCard {
  @apply flex flex-col align-middle justify-center;
  height: 19.25rem;
  width: 20.8125rem;
  text-align: left;
  border-radius: 0.625rem;
  background: #f1f1f1;
  box-shadow:  5px 5px 5px #f8f8f8,
               -5px -5px 5px #fdfdfd;

  &__title {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    font-weight: 700;
    color: #2D3748;
  }

  &__description {
    font-size: 0.875rem;
    color:#718096;
  }

  &__icon {
    height: 3.1875rem;
    width: 3.5rem;
    margin-bottom: 1rem;
  }

  &__container {
    padding-left: 1rem;
    
  }
}

//GRIS AC LIGHT #f1f1f1