.container_certified {
  @apply p-4 md:pl-20 md:pt-10 md:pr-20 animate-pulse;
    display: flex;
    flex-direction: column;
    font-family: "Calibri";
    justify-content: center;
 
    table {
        border-spacing: 0;
        border: 1px solid #d1cccc;
      }
      
      tr:last-child td {
        border-bottom: 0;
      }
      
      th,
      td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid #d1cccc;
        border-right: 1px solid #d1cccc;
      }
      
      th:last-child,
      td:last-child {
        border-right: 0;
      }
      
      tr:nth-child(odd) {
        background: #f3f3f3;
        color: #000000;
      }
      
      tr:nth-child(even) {
        background: #ffffff;
        color: #000000;
      }
      
      input {
        border: 1px solid #d1cccc;
        border-radius: 0.2rem;
        height: 1.5rem;
      }
  }
  
  
  